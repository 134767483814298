import React from 'react';
import ipad from '../assets/images/ipad.png';
import Header from '../components/huntHeader';
import Layout from '../components/Layout';



const Hint1 = () => (
  <Layout>
    <Header /> 

    <header className="masthead1">
      <div className="container d-flex h-100 align-items-center">
        <div className="text-white-50 mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">Hint #1</h1>
          <br></br>
          <br></br>
          <h2 className="tracking-in-expand mx-auto mt-2 mb-5">
            {/* {config.subHeading} */}
            Working from home
            <br></br>
            I’ve got your back.
            <br></br>
            <br></br>
            Take me anywhere
            <br></br>
            I’m easy to pack.
            <br></br>
            <br></br>
            Put me on your lap
            <br></br>
            turn me on.
            <br></br>
            <br></br>
            Here you will find a clue
            <br></br>
            to guide you along.
          </h2>
          <h3 className="roll-in-bottom mx-auto">
          Distance: 50' North</h3>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
            {/* <Scroll type="id" element="about">  
            <a href="#about" className="btn btn-primary">
              Hint
            </a>
          </Scroll> */}
                    <a href="/clue1" className="btn btn-primary">
              Back to Clue
            </a>
          <br></br>
          <br></br>
          <br></br> 
        </div>
      </div>
    </header>

    {/* <Clues />  

    <SocialLinks />
    <Footer /> */}
  </Layout>
);

export default Hint1;
